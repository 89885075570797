import React, { memo } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";
import { CircularProgress, Slider, Select, MenuItem, Card, Switch, createMuiTheme, ThemeProvider } from '@material-ui/core';
import ReactTooltip from "react-tooltip";

// custom classes
import DataMapper from '../utils/DataMapper';
import ColorVisualizer from '../utils/ColorVisualizaer';

const geoUrl = 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json';

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class MapChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      loading: true
    };
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }
  async componentDidMount() {
    // get virus data
    let data = await DataMapper.getData();
    // compute max values (max num deaths, recoveries, etc.)
    const numDates = Object.entries(data)[0][1].length;
    let maxDeaths = Number.MIN_VALUE; // red
    let maxConfirmed = Number.MIN_VALUE; // yellow
    let maxRecovered = Number.MIN_VALUE; // green
    // each of these lists will have the total deaths, confirmed, recovered per day (each day is an element in list)
    const deathList = [];
    const confirmedList = [];
    const recoveredList = [];
    for (let i = 0; i < numDates; i++) {
      let totalDeaths = 0;
      let totalConfirmed = 0;
      let totalRecovered = 0;
      Object.entries(data).forEach(countryList => {
        const countryData = countryList[1][i];
        // compute the max
        if (countryData.confirmed > maxConfirmed) {
          maxConfirmed = countryData.confirmed;
        }
        if (countryData.deaths > maxDeaths) {
          maxDeaths = countryData.deaths;
        }
        if (countryData.recovered > maxRecovered) {
          maxRecovered = countryData.recovered;
        }
        // compute totals
        totalDeaths += countryData.deaths;
        totalConfirmed += countryData.confirmed;
        totalRecovered += countryData.recovered;
      })
      deathList.push(totalDeaths);
      confirmedList.push(totalConfirmed);
      recoveredList.push(totalRecovered);
    }
    this.setState({
      data: data,
      loading: false,
      maxDeaths: maxDeaths,
      maxConfirmed: maxConfirmed,
      maxRecovered: maxRecovered,
      numDates: numDates,
      attribute: 'deaths',
      deathList: deathList,
      confirmedList: confirmedList,
      recoveredList: recoveredList,
      value: numDates - 1,
      country: '',
      darkMode: false
    })
  }
  handleSliderChange(event, newValue) {
    this.setState({
      value: newValue
    });
  }
  handleSelectChange(event) {
    this.setState({
      attribute: event.target.value,
    })
  }
  render() {

    if (this.state.loading === true) {
      return (
        <div style={{
          color: 'white',
          padding: '15px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          Mstransform: 'translateX(-50%) translateY(-50%)',
          WebkitTransform: 'translate(-50%,-50%)',
          transform: 'translate(-50%,-50%)'
        }}>
          <CircularProgress />
        </div>
      )
    } else {
      return (
        <div style={{backgroundColor: this.state.darkMode ? '#0f225c' : '#94d6ff'}}>
          <div >
            <Card id='card' style={{backgroundColor: this.state.darkMode ? 'black' : 'white', color: !this.state.darkMode ? 'black' : 'white'}}>
              <h1>Corona 🌎</h1>
              <hr></hr>
              <h5>Timeline 🕒</h5>
              <p>Scroll the slider below to explore the Coronavirus' effects in the past.</p>
              <Slider
                value={this.state.value}
                min={0}
                step={1}
                max={this.state.numDates - 1}
                onChange={this.handleSliderChange}
              />
              <hr></hr>
              <h5>Statistics 📊</h5>
              <div className='dashboard'>
                <p>📅 {Object.entries(this.state.data)[0][1][this.state.value].date}</p>
                <p>☠️ Deaths: {numberWithCommas(this.state.deathList[this.state.value])}</p>
                <p>🔎 Cases: {numberWithCommas(this.state.confirmedList[this.state.value])}</p>
                <p>❤️ Recovered: {numberWithCommas(this.state.recoveredList[this.state.value])}</p>
              </div>
              <hr></hr>
              <div class="form-group">
                <h5>Heatmap 🎨</h5>
                <p>Select between deaths, confirmed cases, and recovered individuals to observe its visual data.</p>
                <ThemeProvider theme={
                  createMuiTheme({
                    palette: {
                      type: this.state.darkMode ? 'dark' : 'light',
                    },
                  })
                }>
                  <Select
                    value={this.state.attribute}
                    onChange={this.handleSelectChange}
                  >
                    <MenuItem value={'deaths'}>Deaths</MenuItem>
                    <MenuItem value={'confirmed'}>Confirmed</MenuItem>
                    <MenuItem value={'recovered'}>Recovered</MenuItem>
                  </Select>
                </ThemeProvider>
              </div>
              <hr></hr>
              <div>
                <h5>{this.state.darkMode ? 'Dark Mode 🌙' : 'Light Mode ☀️'}</h5>
                <Switch checked={this.state.darkMode} onChange={event => this.setState({darkMode: event.target.checked})} />
              </div>
            </Card>
          </div>
          <div id='big'>
            <ComposableMap projectionConfig={{ scale: 200 }}>
              <ZoomableGroup> <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map(geo => {
                    let { NAME } = geo.properties;
                    // mapping names to values as designated in timeseries json
                    if (NAME === 'United States of America') {
                      NAME = 'US';
                    } else if (NAME === 'Central African Rep.') {
                      NAME = 'Central African Republic';
                    } else if (NAME === 'Dominican Rep.') {
                      NAME = 'Dominican Republic';
                    } else if (NAME === 'Côte d\'Ivoire') {
                      NAME = 'Cote d\'Ivoire';
                    } else if (NAME === 'Taiwan') {
                      NAME = 'Taiwan*';
                    } else if (NAME === 'South Korea') {
                      NAME = 'Korea, South';
                    } else if (NAME === 'Bosnia and Herz.') {
                      NAME = 'Bosnia and Herzegovina';
                    } else if (NAME === 'Myanmar') {
                      NAME = 'Burma';
                    } else if (NAME === 'Macedonia') {
                      NAME = 'North Macedonia'
                    } else if (NAME === 'Eq. Guinea') {
                      NAME = 'Equatorial Guinea'
                    } else if (NAME === 'Dem. Rep. Congo') {
                      NAME = 'Congo (Brazzaville)'
                    } else if (NAME === 'Congo') {
                      NAME = 'Congo (Kinshasa)'
                    }

                    // default color is black
                    let hex = this.state.darkMode ? "FFF" : "000";
                    if (this.state.data[NAME] !== undefined) {
                      let colorValue;
                      switch (this.state.attribute) {
                        case 'deaths':
                          // compute colors 
                          const numDeaths = this.state.data[NAME][this.state.value].deaths
                          colorValue = ColorVisualizer.getColorValue(this.state.maxDeaths, numDeaths);
                          hex = ColorVisualizer.getRGBtoHex(255, colorValue, colorValue);
                          break;
                        case 'recovered':
                          // compute colors
                          const numRecovered = this.state.data[NAME][this.state.value].recovered
                          colorValue = ColorVisualizer.getColorValue(this.state.maxRecovered, numRecovered);
                          hex = ColorVisualizer.getRGBtoHex(colorValue, 255, colorValue);
                          break;
                        case 'confirmed':
                          // compute colors
                          const numConfirmed = this.state.data[NAME][this.state.value].confirmed
                          colorValue = ColorVisualizer.getColorValue(this.state.maxConfirmed, numConfirmed);
                          hex = ColorVisualizer.getRGBtoHex(colorValue, colorValue, 255);
                          break;
                      }
                    }
                    return (
                      <Geography
                        data-tip={NAME + (this.state.data[NAME] ? (this.state.attribute === 'deaths' ?
                          ' - Deaths: ' + numberWithCommas(this.state.data[NAME][this.state.value].deaths) :
                          (this.state.attribute === 'confirmed' ?
                            ' - Confirmed: ' + numberWithCommas(this.state.data[NAME][this.state.value].confirmed) :
                            ' - Recovered: ' + numberWithCommas(this.state.data[NAME][this.state.value].recovered)
                          )) :
                          ' - No data found.')}
                        key={geo.rsmKey}
                        geography={geo}
                        onMouseEnter={() => {
                          ReactTooltip.rebuild();
                        }}
                        onMouseLeave={() => {
                        }}
                        style={{
                          default: {
                            fill: hex,
                            outline: "none"
                          },
                          hover: {
                            fill: "#ffe29e",
                            outline: "none"
                          },
                          pressed: {
                            fill: "#E42",
                            outline: "none"
                          }
                        }}
                      />
                    )
                  })
                }
              </Geographies>
              </ZoomableGroup>
            </ComposableMap>
          </div>
        </div>
      );
    }
  }
};

export default memo(MapChart);
