
const cors = 'https://cors-anywhere.herokuapp.com/';
const dataSource = 'https://pomber.github.io/covid19/timeseries.json';

const DataMapper = {
    getData: async () => {
        const data = await fetch(cors + dataSource)
            .then(res => res.json());
        return data;
    },
}

export default DataMapper;
