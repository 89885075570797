const ColorVisualizer = {
    getColorValue: (max, val) => {
        return parseInt(Math.abs((220 - (210) * (val / max))).toString());
    },
    getRGBtoHex: (r, g, b) => {
        const componentToHex = c => {
            const hex = c.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        }
        return componentToHex(r) + componentToHex(g) + componentToHex(b);
    }
}

export default ColorVisualizer;