import React from 'react';
import './App.css';
import MapChart from './components/Map';
import ReactTooltip from "react-tooltip";


const App = () => {
    return (
        <div className="App">
            <div >
                <ReactTooltip />
                <MapChart />
            </div>
        </div>
    );
}

export default App;
